<template>
<div>
  <!-- Start Service Area  -->
  <v-row class="d-flex justify-content mt-16">
    <v-col>
        <div class="d-flex flex-column">
            <h5 class="judul-fitur">
                Inventory System and Asset Management
            </h5>
            <p class="isi-fitur">
                Curating, managing and storing information related to the use, absorption and function of assets.
            </p>
        </div>
    </v-col>
    <!-- </v-col> -->
    <v-col>
        <v-img class="foto-fitur" width="500" src="../../assets/images/featured/circle-loop.png" />
    </v-col>
    <!-- <v-col> -->
    <v-col>
        <div class="d-flex flex-column mb-5">
            <h5 class="judul-fitur">
                Integrated Reporting System
            </h5>
            <p class="isi-fitur">
                Combines manual reporting and automated reporting delivered via a unified sensor device.
            </p>
        </div>
        <div class="d-flex flex-column">
            <h5 class="judul-fitur">
                Asset Data Visualization
            </h5>
            <p class="isi-fitur">
                Displays the position along with supporting data regarding the asset on the GIS map.
            </p>
        </div>
    </v-col>
</v-row>
  <!-- Start Service Area  -->
</div>
</template>

<script>
import feather from "feather-icons";

  export default {
    components: {
    },
      data() {
        return {
          
        };
      },
      methods: {
        iconSvg(icon) {
          return feather.icons[icon].toSvg();
        },
      },
    };
</script>
<style scoped>
.judul-fitur {
    font-size: 24px;
    font-weight: 400;
}
.isi-fitur {
    font-size: 18px;
    font-weight: 300;
    color: #717173;
}
</style>
